.TopicList__hidden--1R5bF {
  display: none;
}
.TopicList__container--3_SZT {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  position: relative;
  overflow: auto;
}
@media only screen and (max-width: 992px) {
  .TopicList__container--3_SZT {
    padding: 16px;
    box-shadow: none;
    border-radius: 0;
  }
}
.TopicList__menuItem--3vCz8 {
  font-size: 16px;
  color: #4a4a4a;
  list-style: none;
  border-bottom: solid 1px rgba(151, 151, 151, 0.1);
  min-height: 46px;
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.TopicList__menuItem--3vCz8:hover {
  background-color: #eee;
}
.TopicList__menuItem--3vCz8:hover a {
  color: #f26118;
}
.TopicList__menuItem--3vCz8 a {
  padding-left: 10px;
  display: block;
  color: #4a4a4a;
  text-decoration: none;
  width: 100%;
}
.TopicList__menuItem--3vCz8 a.TopicList__selected--3UsFw {
  color: #f26118;
}
@media only screen and (max-width: 992px) {
  .TopicList__menuItem--3vCz8 a.TopicList__selected--3UsFw {
    background-color: #f7f7f7;
  }
}
.TopicList__menuItem--3vCz8 a:focus {
  background-color: transparent;
}
