.cap-message {
  z-index: 1010;
  width: 100%;
  pointer-events: none;
  height: 72px;
  opacity: 0.9;
  font-size: 18px;
  line-height: 72px;
  color: #ffffff;
  padding: 0;
  text-align: center;
}
.Message__affixContainer--2LoGh {
  position: absolute;
  top: 64px;
  z-index: 1010;
  width: 100%;
  left: 0;
}
.Message__content--2D5oS {
  width: 100%;
  height: 72px;
  opacity: 0.9;
  font-size: 18px;
  color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .Message__content--2D5oS {
    padding: 0 15px;
  }
}
.Message__content--2D5oS.Message__status-error--R1Oai {
  background-color: #f24d18;
}
.Message__content--2D5oS.Message__status-warning--1_b_P {
  background-color: #ee8514;
}
.Message__content--2D5oS.Message__status-success--3vg44 {
  background-color: #94d50f;
}
.Message__content--2D5oS.Message__status-info--3z8Nn,
.Message__content--2D5oS.Message__status-loading--1F3qg {
  background-color: #30b8e9;
}
.Message__icon--368Rr {
  fill: #ffffff;
  margin-right: 25px;
}
