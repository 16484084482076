.Menu__menuContainer--BDA0Z {
  margin-top: 20px;
  margin-bottom: 35px;
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO {
  font-size: 16px;
  color: #4a4a4a;
  list-style: none;
  border-bottom: solid 1px rgba(151, 151, 151, 0.1);
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO:hover {
  background-color: #eee;
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO:hover a {
  color: #f26118;
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO a {
  padding-left: 10px;
  line-height: 46px;
  display: block;
  height: 46px;
  color: #4a4a4a;
  text-decoration: none;
  width: 100%;
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO a.Menu__selected--10Mb9 {
  color: #f26118;
}
@media only screen and (max-width: 992px) {
  .Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO a.Menu__selected--10Mb9 {
    background-color: #f7f7f7;
  }
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO a:focus {
  background-color: transparent;
}
.Menu__menuContainer--BDA0Z .Menu__menuItem--3MwfO.Menu__promo--2klLW a {
  font-weight: bold;
  color: #f26118;
}
@media only screen and (max-width: 992px) {
  .Menu__menuContainer--BDA0Z {
    margin-top: 0;
    z-index: 99;
  }
}
.Menu__title--Hrtt1 {
  font-size: 36px;
  color: #4a4a4a;
  margin-left: 10px;
  margin-bottom: 0;
}
