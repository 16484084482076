.UserMenu__hidden--3NQsa {
  display: none;
}
.UserMenu__topSection--3iHRP {
  display: flex;
  padding: 19px 24px 16px 26px;
  align-items: center;
  flex-wrap: wrap;
}
.UserMenu__topSection--3iHRP .UserMenu__titles--3J9kr {
  display: flex;
  flex-direction: column;
}
.UserMenu__topSection--3iHRP .UserMenu__username--Tj_Ca {
  font-size: 20px;
  color: #161515;
  font-weight: bold;
}
.UserMenu__topSection--3iHRP .UserMenu__username--Tj_Ca:hover {
  color: #f26118;
}
.UserMenu__topSection--3iHRP .UserMenu__email--f54Ox {
  font-size: 12px;
  color: #9b9b9b;
  line-height: 14px;
  flex-basis: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__editIcon--1-emS {
  fill: #9b9b9b;
  width: 24px;
  margin-right: 7px;
  height: auto;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe svg {
  fill: #9b9b9b;
  width: 28px;
  height: auto;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-badge-count {
  font-size: 10px;
  height: 14px;
  line-height: 14px;
  min-width: 14px;
  box-shadow: none;
  padding: 0 4px;
  background-color: #f26118;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-badge-multiple-words {
  padding: 0 4px;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-badge {
  vertical-align: initial;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-scroll-number-only {
  height: 14px;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-scroll-number-only > p {
  height: 14px;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-badge-dot {
  box-shadow: none;
  background-color: #f26118;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-badge-count {
  margin-top: 7px;
  margin-left: -4px;
  height: 12px;
  line-height: 12px;
  min-width: 7px;
  font-size: 10px;
  border-radius: 8px;
  padding: 0 3px;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-scroll-number-only {
  height: 12px;
}
.UserMenu__topSection--3iHRP .UserMenu__rightBlock--1KTip .UserMenu__notifications--3yfEe .ant-scroll-number-only > p {
  height: 12px;
}
.UserMenu__balanceSection--219fh {
  background-color: #fafafa;
  padding: 13px 24px;
  display: flex;
  align-items: center;
}
.UserMenu__balanceSection--219fh .UserMenu__topUp--3IGFy {
  margin-left: auto;
  height: 36px;
  border: 1px solid #f26118;
  border-radius: 3px;
  color: #f26118;
  padding: 7px 17px;
  font-size: 14px;
}
.UserMenu__balanceSection--219fh .UserMenu__redeemCoupon--3pUhL {
  font-size: 14px;
  color: #f26118;
  margin-top: 2px;
}
.UserMenu__balanceSection--219fh .UserMenu__balance--3qb0r {
  display: flex;
  flex-direction: column;
}
.UserMenu__balanceSection--219fh .UserMenu__balance--3qb0r .UserMenu__title--3siv_ {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 12px;
}
.UserMenu__balanceSection--219fh .UserMenu__balance--3qb0r .UserMenu__value--3jQva {
  font-weight: 300;
  font-size: 18px;
  color: #000;
  text-decoration: none;
}
.UserMenu__balanceSection--219fh .UserMenu__balance--3qb0r .UserMenu__value--3jQva strong {
  font-weight: 700;
}
.UserMenu__balanceSection--219fh .UserMenu__balance--3qb0r .UserMenu__value--3jQva.UserMenu__negativeBalance--23Nf3 {
  color: #f5222d;
}
.UserMenu__menuSection--2baV0 {
  padding: 6px 24px 26px;
}
.UserMenu__menuSection--2baV0 li {
  border-bottom: 1px solid #f4f4f4;
}
.UserMenu__menuSection--2baV0 li a {
  display: inline-block;
  width: 100%;
  padding: 11px 10px;
  font-size: 16px;
  color: #4a4a4a;
}
.UserMenu__menuSection--2baV0 li a:hover {
  background-color: #eee;
  color: #f26118;
}
.UserMenu__userMenuPopover--2njdp .ant-popover-inner {
  width: 307px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background-clip: border-box;
}
.UserMenu__userMenuPopover--2njdp .ant-popover-inner-content {
  padding: 0;
}
.UserMenu__discount--2Y9T- {
  text-align: center;
  padding: 15px 0;
  margin-bottom: -3px;
  color: #f26118;
  background-color: #f8f8f8;
}
