body {
  background: #f4f4f4;
}
html,
body {
  min-height: 100%;
  height: auto;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-input-placeholder {
  color: #aaa;
  font-weight: 300;
}
::-moz-placeholder {
  color: #aaa;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #aaa;
  font-weight: 300;
}
input:-moz-placeholder {
  color: #aaa;
  font-weight: 300;
}
ul,
ol {
  list-style: none;
}
.visible-mobile {
  display: block;
}
.visible-desktop {
  display: block;
}
.visible-tablet {
  display: block;
}
@media only screen and (max-width: 577px) {
  .visible-desktop {
    display: none !important;
  }
  .visible-tablet {
    display: none !important;
  }
  .hidden-mobile {
    display: none !important;
  }
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .visible-desktop {
    display: none !important;
  }
  .visible-mobile {
    display: none !important;
  }
  .hidden-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .visible-mobile {
    display: none !important;
  }
  .visible-tablet {
    display: none !important;
  }
  .hidden-desktop {
    display: none !important;
  }
}
.disable-scroll {
  overflow: hidden;
  height: 100%;
}
