.LoadingContainer__hidden--2mXHg {
  display: none;
}
.LoadingContainer__container--1IPo_ {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  position: relative;
  overflow: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .LoadingContainer__container--1IPo_ {
    padding: 16px;
    box-shadow: none;
    border-radius: 0;
  }
}
.LoadingContainer__disk--I-Djf {
  margin: 50px 0;
  width: 160px;
  height: auto;
  animation-name: LoadingContainer__spin--2xNt8;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.LoadingContainer__text--Pc8uB {
  font-size: 20px;
}
@keyframes LoadingContainer__spin--2xNt8 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
