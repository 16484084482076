.Error__container--Ew9VA {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 885px;
}
@media only screen and (max-width: 577px) {
  .Error__container--Ew9VA {
    height: 410px;
    min-height: 100vh;
  }
}
.Error__num--3_eDM {
  color: #f26118;
  font-size: 294.4px;
  font-weight: bold;
  line-height: 223px;
  display: flex;
  text-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 80px auto 50px;
}
@media only screen and (max-width: 577px) {
  .Error__num--3_eDM {
    font-size: 136px;
    margin-bottom: 0;
    margin-top: 40px;
  }
}
.Error__searchIcon--3u_X_,
.Error__cancelIcon--vhkPI {
  position: absolute;
  left: 0;
  right: 0;
  fill: #fff;
  z-index: -1;
  top: -65px;
  width: 860px;
  height: auto;
  margin: 0 auto;
}
@media only screen and (max-width: 577px) {
  .Error__searchIcon--3u_X_,
  .Error__cancelIcon--vhkPI {
    width: calc(100vw + 50px);
    left: -20px;
    top: 70px;
  }
}
.Error__cancelIcon--vhkPI {
  top: -26px;
  width: 100%;
}
@media only screen and (max-width: 577px) {
  .Error__cancelIcon--vhkPI {
    left: -26px;
    width: calc(100vw + 50px);
  }
}
.Error__title--2AQix {
  color: #f26118;
  font-size: 57.6px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
}
@media only screen and (max-width: 577px) {
  .Error__title--2AQix {
    font-size: 24px;
  }
}
.Error__disk--Uxxui {
  margin: 0 20px;
}
@media only screen and (max-width: 577px) {
  .Error__disk--Uxxui {
    margin: 0 14px;
    height: 100px;
    width: 100px;
  }
}
.Error__descr--YPU3e {
  text-align: center;
  font-size: 24px;
}
@media only screen and (max-width: 577px) {
  .Error__descr--YPU3e {
    font-size: 16px;
  }
}
