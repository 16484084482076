.Sider__container--1BR9f {
  flex: 0 0 150px;
}
.Sider__container--1BR9f.Sider__help--2KtSA {
  flex-basis: 268px;
}
@media only screen and (max-width: 992px) {
  .Sider__container--1BR9f .Sider__sider--20XZu {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: #ffffff;
    padding-left: 24px;
  }
}
@media only screen and (max-width: 992px) {
  .Sider__container--1BR9f {
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    position: fixed;
    transition: left linear 0.12s;
    z-index: 99;
    padding: 0;
  }
}
.Sider__container--1BR9f .Sider__backdrop--OaDW4 {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 98;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
}
.Sider__container--1BR9f.Sider__visible--2ORv8 {
  left: 0;
  display: block;
}
.Sider__container--1BR9f .ant-menu {
  background-color: transparent;
  border: none;
}
@media (max-width: 767px) {
  .Sider__container--1BR9f .ant-menu {
    background-color: #161515;
    color: #fff;
  }
  .Sider__container--1BR9f .ant-menu .ant-menu-item {
    font-size: 16px;
  }
}
.Sider__container--1BR9f .Sider__visible--2ORv8 {
  display: block;
}
.Sider__container--1BR9f .Sider__logoWrap--1VNfC {
  background-color: #f6f6f6;
  height: 64px;
  padding: 0 16px;
  margin-left: -24px;
  display: flex;
  align-items: center;
}
.Sider__container--1BR9f .Sider__logoWrap--1VNfC .Sider__logo--2Cdv8 {
  margin-left: 60px;
  height: 48px;
}
.Sider__container--1BR9f .Sider__logoWrap--1VNfC .Sider__closeIcon--2VoN0 {
  margin-left: auto;
  fill: #8a8a8a;
}
.Sider__container--1BR9f .Sider__userBlock--2Y6l5 {
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 0 16px 0 0;
}
.Sider__container--1BR9f .Sider__userBlock--2Y6l5 .Sider__avatar--1VXvq {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-image: linear-gradient(314deg, #ff5147, #ee8514);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}
.Sider__container--1BR9f .Sider__userBlock--2Y6l5 .Sider__avatar--1VXvq .Sider__personIcon--2kLaI {
  width: 24px;
  height: 24px;
  fill: #fff;
}
.Sider__container--1BR9f .Sider__userBlock--2Y6l5 .Sider__settingsLink--2wC9h {
  line-height: 0;
  margin-left: auto;
}
.Sider__container--1BR9f .Sider__userBlock--2Y6l5 .Sider__settingsLink--2wC9h .Sider__settingsIcon--JvUnU {
  fill: #8a8a8a;
}
