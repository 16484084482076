.ContactUsModal__hidden--3xCYw {
  display: none;
}
.ContactUsModal__modal--2P0g7 .ant-modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  min-height: 48px;
  align-items: center;
  padding: 30px 0 5px;
  margin: 0 20px 20px;
}
.ContactUsModal__modal--2P0g7 .ant-modal-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  line-height: 28px;
  color: #4a4a4a;
}
.ContactUsModal__modal--2P0g7 .ant-modal-close-x {
  margin-top: 5px;
}
.ContactUsModal__modal--2P0g7 .ant-modal-close-x:before {
  font-size: 20px;
  color: #4a4a4a;
}
