.Footer__footer--1IN3Q {
  width: 100%;
  background-color: #161515;
  min-width: 320px;
  z-index: 10;
}
.Footer__footer--1IN3Q .Footer__innerFooter--3c_hA {
  max-width: 1200px;
  width: 100%;
  display: flex;
  padding: 40px 28px;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}
@media (min-width: 1920px) {
  .Footer__footer--1IN3Q .Footer__innerFooter--3c_hA {
    max-width: 1440px;
  }
}
@media only screen and (max-width: 577px) {
  .Footer__footer--1IN3Q .Footer__innerFooter--3c_hA {
    flex-direction: column;
  }
}
.Footer__footer--1IN3Q .Footer__feedList--3eKDT {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}
.Footer__footer--1IN3Q .Footer__feedList--3eKDT li {
  margin: 0 10px;
}
.Footer__footer--1IN3Q .Footer__leftBlock--nMWzm .Footer__logo--392EC {
  margin: 0 0 20px;
  height: 56px;
}
@media only screen and (max-width: 577px) {
  .Footer__footer--1IN3Q .Footer__leftBlock--nMWzm {
    text-align: center;
  }
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG .Footer__textLinks--1xTal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px 5px;
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG .Footer__textLinks--1xTal li {
  margin: 0 15px 5px;
  white-space: nowrap;
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG .Footer__textLinks--1xTal li a {
  font-size: 14px;
  text-align: left;
  color: #ffffff;
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG .Footer__socialLinks--3JhY2 {
  display: flex;
  margin-top: 20px;
  width: 90px;
  justify-content: space-between;
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG .Footer__socialLinks--3JhY2 li {
  flex: 0 0 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.Footer__footer--1IN3Q .Footer__rightBlock--1HbBG .Footer__socialLinks--3JhY2 .Footer__socialIcon--23fYr {
  color: #ffffff;
}
@media only screen and (max-width: 577px) {
  .Footer__footer--1IN3Q .Footer__rightBlock--1HbBG {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}
.Footer__footer--1IN3Q .Footer__copyrights--SVAN9 {
  opacity: 0.3;
  font-size: 14px;
  color: #ffffff;
  flex-basis: 100%;
}
@media only screen and (max-width: 577px) {
  .Footer__footer--1IN3Q .Footer__copyrights--SVAN9 {
    text-align: center;
  }
}
