@media only screen and (max-width: 577px) {
  .header-expanded .App__contentWrap--ZiyRG {
    margin-top: 64px;
    min-height: calc(100vh - 128px);
  }
}
.App__appContainer--nwCal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 320px;
}
.App__appContainer--nwCal .ant-layout {
  background-color: transparent;
}
.App__container--1AssU {
  flex-direction: row;
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-grow: 1;
}
@media (min-width: 1920px) {
  .App__container--1AssU {
    max-width: 1440px;
  }
}
@media only screen and (max-width: 577px) {
  .App__container--1AssU:not(.App__hasErrorMsg--1lXWD) {
    padding-top: 64px;
  }
}
.App__contentWrap--ZiyRG {
  background: #fff;
  min-height: calc(100vh - 64px);
  width: calc(100% - 150px);
}
@media only screen and (max-width: 992px) {
  .App__contentWrap--ZiyRG {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .App__contentWrap--ZiyRG {
    padding: 0;
    width: 100%;
  }
}
.App__contentContainer--7BcAZ {
  margin: 24px 10px 69px 50px;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.App__contentContainer--7BcAZ > div {
  width: 100%;
  min-width: 320px;
}
@media only screen and (max-width: 992px) {
  .App__contentContainer--7BcAZ > div {
    max-width: 100vw;
  }
}
@media only screen and (max-width: 992px) {
  .App__contentContainer--7BcAZ {
    margin: 0;
  }
}
