.SystemMessage__systemMsg--4Vueo {
  color: #ffffff;
  background-color: #f26118;
  width: 100%;
  padding: 5px 7px;
  text-align: center;
  font-size: 16px;
}
@media only screen and (max-width: 577px) {
  .SystemMessage__systemMsg--4Vueo {
    margin-top: 64px;
  }
}
