.Search__certainCategoryIcon--3rXM9 {
  color: #fff;
  font-size: 26px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-right: 16px;
}
@media only screen and (max-width: 992px) {
  .Search__certainCategoryIcon--3rXM9 {
    display: none;
  }
}
.Search__certainCategoryIcon--3rXM9.Search__activeIcon--2hKde {
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .Search__certainCategoryIcon--3rXM9.Search__activeIcon--2hKde {
    display: block;
  }
}
.Search__certainCategorySearchWrapper--2tdAk {
  width: 100%;
}
.Search__input--3Uy7B {
  height: 48px;
}
@media only screen and (max-width: 992px) {
  .Search__input--3Uy7B {
    height: 64px;
  }
}
.Search__input--3Uy7B .ant-input {
  font-size: 18px;
  background-color: #444343;
  border: none;
  padding: 0 25px;
  border-radius: 10px;
  color: #fff;
  outline: none;
  line-height: 48px;
}
.Search__input--3Uy7B .ant-input:focus {
  box-shadow: none;
}
@media only screen and (max-width: 992px) {
  .Search__input--3Uy7B .ant-input {
    padding: 0 13px;
    border-radius: 0;
  }
}
.Search__input--3Uy7B .ant-input-affix-wrapper .ant-input-suffix {
  right: 4px;
}
@media only screen and (max-width: 992px) {
  .Search__input--3Uy7B .ant-input-affix-wrapper .ant-input-suffix {
    right: 0 !important;
  }
}
.Search__inAction--1ImEz .Search__activeIcon--2hKde {
  color: #000;
}
.Search__inAction--1ImEz .ant-input {
  background-color: #ffffff;
  color: #f26118;
}
