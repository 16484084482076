.HelpMenu__container--2HC68 .HelpMenu__list--3pk9_ {
  max-height: calc(100vh - 240px);
  overflow-y: scroll;
  min-height: 400px;
}
.HelpMenu__container--2HC68 .HelpMenu__list--3pk9_::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
.HelpMenu__container--2HC68 .HelpMenu__list--3pk9_::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.HelpMenu__container--2HC68 .HelpMenu__item--3Euo7 {
  display: flex;
  align-items: center;
  min-height: 46px;
}
.HelpMenu__container--2HC68 .HelpMenu__item--3Euo7 a {
  line-height: 20px;
  height: auto;
  padding: 12px 0 12px 10px;
}
