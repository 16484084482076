.CartIndicator__hidden--1JYA5 {
  display: none;
}
.CartIndicator__wrapper--1qHJR {
  outline: none;
  display: flex;
  align-items: center;
}
.CartIndicator__wrapper--1qHJR:hover,
.CartIndicator__wrapper--1qHJR:active,
.CartIndicator__wrapper--1qHJR:focus {
  text-decoration: none;
  color: #ffffff;
}
.CartIndicator__wrapper--1qHJR .CartIndicator__balance--3PEpy {
  font-size: 16px;
  font-weight: 200;
  margin-left: 7px;
  margin-top: -2px;
}
.CartIndicator__wrapper--1qHJR .CartIndicator__balance--3PEpy strong {
  font-weight: 700;
}
.CartIndicator__wrapper--1qHJR .ant-badge-count {
  font-size: 10px;
  height: 14px;
  line-height: 14px;
  min-width: 14px;
  box-shadow: none;
  padding: 0 4px;
  background-color: #f26118;
}
.CartIndicator__wrapper--1qHJR .ant-badge-multiple-words {
  padding: 0 4px;
}
.CartIndicator__wrapper--1qHJR .ant-badge {
  vertical-align: initial;
}
.CartIndicator__wrapper--1qHJR .ant-scroll-number-only {
  height: 14px;
}
.CartIndicator__wrapper--1qHJR .ant-scroll-number-only > p {
  height: 14px;
}
.CartIndicator__wrapper--1qHJR .ant-badge-dot {
  box-shadow: none;
  background-color: #f26118;
}
