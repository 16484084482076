.SheetTitle__hidden--1bK78 {
  display: none;
}
.SheetTitle__sectionTitle--3sYxs {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  min-height: 38px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 26px;
}
.SheetTitle__sectionHeader--3xMOA {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
  color: #4a4a4a;
  flex-grow: 1;
}
