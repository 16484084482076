.Header__hidden--3mNur {
  display: none;
}
@media only screen and (max-width: 992px) {
  .header-expanded .Header__headerContainer--3XuQA {
    height: 128px;
  }
}
.Header__innerHeader--3H3W1 {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  margin: 0 auto;
  padding: 0 18px;
}
@media (min-width: 1920px) {
  .Header__innerHeader--3H3W1 {
    max-width: 1440px;
  }
}
@media only screen and (max-width: 992px) {
  .Header__innerHeader--3H3W1 {
    padding: 0 24px;
  }
}
.Header__innerHeader--3H3W1 .Header__logoLink--36dJo {
  line-height: 0;
}
.Header__innerHeader--3H3W1 .Header__triggerIcon--1BMV_ {
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  width: 28px;
  margin-top: -4px;
}
.Header__innerHeader--3H3W1 .Header__searchIcon--1Bk-B {
  margin-right: 9px;
}
.Header__innerHeader--3H3W1 .Header__searchIcon--1Bk-B svg {
  margin-top: 0;
  height: auto;
  width: 34px;
}
.Header__headerContainer--3XuQA {
  background-color: #161515;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  min-width: 320px;
}
@media only screen and (max-width: 577px) {
  .Header__headerContainer--3XuQA {
    z-index: 99;
    position: fixed;
  }
}
@media only screen and (max-width: 577px) {
  .Header__header--3WDWS {
    height: 40px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 992px) {
  .Header__header--3WDWS {
    padding: 0 40px;
  }
}
.Header__searchInput--3JClR {
  flex: 1;
  margin: 0 52px 0 70px;
}
.Header__searchInput--3JClR.Header__searchInputVisible--3nA9z {
  display: block !important;
  margin-left: 0;
  box-shadow: 0 0 10px 0 #f26118;
}
@media only screen and (max-width: 992px) {
  .Header__searchInput--3JClR {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
  }
}
.Header__accountIcon--1YW9G {
  color: #fff;
  align-self: center;
}
.Header__icons--1nywN {
  fill: #fff;
  display: block;
}
.Header__notificationIndicator--kIHHA {
  cursor: pointer;
  margin: 0 10px 0 20px;
}
.Header__rightIconsWrapper--1IWh9 {
  display: flex;
  align-items: center;
  margin: 0 -15px;
  margin-left: auto;
}
.Header__headerSeparator--ykikC {
  flex: 1 0;
  margin: 0 15px;
  border-left: 1px solid;
}
.Header__logo--4H9G8 {
  height: 46px;
  display: block;
}
.Header__anonymousLogin--3OXwD {
  font-size: 16px;
}
.Header__anonymousLogin--3OXwD a {
  color: #ffffff;
  text-decoration: none;
}
.Header__anonymousLogin--3OXwD a:hover,
.Header__anonymousLogin--3OXwD a:active {
  color: #ffffff;
}
.Header__anonymousLoginIcon--1TmXO {
  font-size: 20px;
  vertical-align: bottom;
  margin-right: 10px;
}
@media only screen and (max-width: 992px) {
  .Header__userMenuToggle--1Abyz.ant-popover-open .Header__personIcon--3btqD::after {
    display: block;
  }
  .Header__userMenuToggle--1Abyz.ant-popover-open .Header__personIcon--3btqD path {
    stroke: #f26118;
  }
}
.Header__userMenuToggle--1Abyz {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 29px 0 15px;
  border: none;
  background: transparent;
  padding: 5px 0 0;
  outline: none;
}
@media only screen and (max-width: 992px) {
  .Header__userMenuToggle--1Abyz {
    margin-right: 15px;
  }
}
.Header__userMenuToggle--1Abyz .Header__userInfo--leG-D {
  margin-left: 10px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: -5px;
}
.Header__userMenuToggle--1Abyz .Header__userInfo--leG-D .Header__username--1Sje6 {
  line-height: 16px;
  font-size: 16px;
  margin-top: -2px;
}
.Header__userMenuToggle--1Abyz .Header__userInfo--leG-D span {
  font-size: 12px;
  line-height: 14px;
  color: #9b9b9b;
  font-weight: 200;
  margin-top: 1px;
}
.Header__userMenuToggle--1Abyz .Header__userInfo--leG-D span strong {
  font-weight: 700;
}
.Header__userMenuToggle--1Abyz .Header__userInfo--leG-D .Header__balance--2fIlL {
  color: #f26118;
  font-weight: bold;
}
.Header__personIcon--3btqD {
  display: block;
  position: relative;
}
.Header__personIcon--3btqD svg {
  height: auto;
  width: 24px;
  display: inline-block;
}
.Header__personIcon--3btqD::after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  opacity: 0.23;
  background-color: #d8d8d8;
  display: none;
  top: -12px;
  right: -12px;
}
.Header__personIcon--3btqD .ant-badge-count {
  font-size: 10px;
  height: 14px;
  line-height: 14px;
  min-width: 14px;
  box-shadow: none;
  padding: 0 4px;
  background-color: #f26118;
}
.Header__personIcon--3btqD .ant-badge-multiple-words {
  padding: 0 4px;
}
.Header__personIcon--3btqD .ant-badge {
  vertical-align: initial;
}
.Header__personIcon--3btqD .ant-scroll-number-only {
  height: 14px;
}
.Header__personIcon--3btqD .ant-scroll-number-only > p {
  height: 14px;
}
.Header__personIcon--3btqD .ant-badge-dot {
  box-shadow: none;
  background-color: #f26118;
}
.Header__menuFold--3Ql3N {
  border: 0;
  background-color: transparent;
  margin-top: 4px;
}
@media only screen and (min-width: 578px) and (max-width: 992px) {
  .Header__menuFold--3Ql3N {
    margin-right: 20px;
  }
}
.Header__logoPlaceholder--1N5MQ {
  margin-left: 10px;
  margin-top: 0;
}
.Header__logoPlaceholder--1N5MQ a {
  display: block;
}
@media only screen and (max-width: 992px) {
  .Header__logoPlaceholder--1N5MQ {
    margin-left: 27px;
  }
}
.Header__headerIcon--2gOZl {
  margin: 0 15px;
  position: relative;
}
@media only screen and (max-width: 577px) {
  .Header__headerIcon--2gOZl {
    margin: 0 8px;
  }
}
.Header__headerIcon--2gOZl button {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.Header__headerIcon--2gOZl a,
.Header__headerIcon--2gOZl button {
  line-height: 0;
  display: block;
  outline: none;
}
.Header__headerIcon--2gOZl.Header__searchIconActive--3YYoO svg {
  fill: #f26118;
}
.Header__headerIcon--2gOZl.Header__searchIconActive--3YYoO:after {
  content: "";
  background-color: #2d2c2c;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -7px;
  display: block;
  z-index: 0;
}
.Header__headerIcon--2gOZl.Header__cartIndicator--1OArO {
  color: #fff;
  font-size: 26px;
  margin-top: 2px;
  margin-right: 15px;
}
@media only screen and (max-width: 992px) {
  .Header__headerIcon--2gOZl.Header__cartIndicator--1OArO {
    margin-right: 17px;
    margin-left: 17px;
  }
}
.Header__headerIcon--2gOZl.Header__cartIndicator--1OArO svg {
  height: auto;
  width: 24px;
}
.Header__loginBtn--69v_0,
.Header__signUpBtn--ryLVy {
  font-size: 16px;
  height: 36px;
}
.Header__loginBtn--69v_0:hover,
.Header__signUpBtn--ryLVy:hover,
.Header__loginBtn--69v_0:focus,
.Header__signUpBtn--ryLVy:focus,
.Header__loginBtn--69v_0:active,
.Header__signUpBtn--ryLVy:active {
  text-decoration: none;
}
.Header__loginBtn--69v_0 {
  color: #ffffff;
}
.Header__signUpBtn--ryLVy {
  border-radius: 2px;
  background-color: #262626;
  border: 1px solid #fc4a1a;
  color: #ff8400;
  padding: 0 10px;
  margin: 0 25px;
  transition: color 0.3s, border-color 0.3s;
}
.Header__signUpBtn--ryLVy:hover {
  color: #ffffff;
  border-color: #ffffff;
}
.Header__musicLink--1PTPW {
  line-height: 0;
}
.Header__musicLink--1PTPW svg {
  height: auto;
  width: 38px;
  margin-top: -1px;
}
.Header__musicLink--1PTPW .st0 {
  fill: #ffffff;
}
.Header__musicLink--1PTPW .st1,
.Header__musicLink--1PTPW .st2 {
  stroke: #ffffff;
}
