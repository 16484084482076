.ContactUsForm__hidden--3Ch3C {
  display: none;
}
.ContactUsForm__input--1vgsC {
  margin-bottom: 24px;
}
.ContactUsForm__input--1vgsC .has-error .ant-input {
  border-color: #f5222d;
}
.ContactUsForm__input--1vgsC .has-error .ant-form-explain {
  color: #e62f4a;
  font-size: 12px;
  margin-top: 4px;
}
.ContactUsForm__input--1vgsC .ant-select-auto-complete.ant-select .ant-input,
.ContactUsForm__input--1vgsC .ant-input {
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #dee0e3;
  height: 48px;
  font-size: 18px;
  color: #333333;
  box-shadow: none;
  line-height: 38px;
}
.ContactUsForm__input--1vgsC .ant-select-auto-complete.ant-select .ant-input:-ms-input-placeholder,
.ContactUsForm__input--1vgsC .ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ContactUsForm__input--1vgsC .ant-select-auto-complete.ant-select .ant-input:hover,
.ContactUsForm__input--1vgsC .ant-input:hover,
.ContactUsForm__input--1vgsC .ant-select-auto-complete.ant-select .ant-input:focus,
.ContactUsForm__input--1vgsC .ant-input:focus {
  border-color: #fc9903;
  box-shadow: none;
}
.ContactUsForm__input--1vgsC .ant-input-disabled {
  background-color: #f5f5f5;
}
.ContactUsForm__input--1vgsC textarea.ant-input {
  line-height: 28px;
}
.ContactUsForm__input--1vgsC .ant-select-selection--single {
  height: 48px;
}
.ContactUsForm__input--1vgsC .ant-select-selection--single .ant-select-selection__rendered {
  line-height: 48px;
}
.ContactUsForm__input--1vgsC .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value,
.ContactUsForm__input--1vgsC .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection__placeholder {
  font-size: 18px;
}
.ContactUsForm__input--1vgsC .ant-form-explain ul {
  margin-bottom: 0;
}
.ContactUsForm__label--3X4UL {
  height: 48px;
  line-height: 18px;
  font-size: 16px;
  height: 46px;
  display: flex;
  align-items: center;
}
.ContactUsForm__label--3X4UL sup {
  color: #f26118;
}
.ContactUsForm__submit--1jCbS {
  margin-left: auto;
  border-radius: 4px;
  background-color: #30b8e9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: 48px;
  width: 205px;
  display: block;
  border-width: 0;
  text-transform: uppercase;
  margin-top: 46px;
  line-height: 48px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  transition: 0.2s ease-in-out;
  width: 180px;
  margin-top: 0;
}
@media only screen and (max-width: 577px) {
  .ContactUsForm__submit--1jCbS {
    width: 100%;
  }
}
.ContactUsForm__submit--1jCbS:active,
.ContactUsForm__submit--1jCbS:focus,
.ContactUsForm__submit--1jCbS:hover {
  border-color: transparent;
  background-color: #30b8e9;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ContactUsForm__submit--1jCbS[disabled] {
  background-color: #d7d7d7;
  color: #9b9b9b;
  cursor: not-allowed;
}
@media only screen and (max-width: 992px) {
  .ContactUsForm__submit--1jCbS {
    width: 100%;
    margin-bottom: 20px;
  }
}
.ContactUsForm__successMessage--1LHrm {
  margin: 32px auto 10px;
  text-align: center;
}
.ContactUsForm__successMessage--1LHrm .ContactUsForm__messageIcon--3f_Wo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 28px;
}
.ContactUsForm__successMessage--1LHrm .ContactUsForm__messageIcon--3f_Wo svg {
  fill: #30b8e9;
  width: 72px;
  height: 72px;
}
.ContactUsForm__successMessage--1LHrm p {
  font-size: 18px;
}
.ContactUsForm__successMessage--1LHrm .ContactUsForm__okButton--200TW {
  margin-left: auto;
  border-radius: 4px;
  background-color: #30b8e9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: 48px;
  width: 205px;
  display: block;
  border-width: 0;
  text-transform: uppercase;
  margin-top: 46px;
  line-height: 48px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  transition: 0.2s ease-in-out;
  margin-top: 50px;
  width: 135px;
  margin-right: auto;
}
@media only screen and (max-width: 577px) {
  .ContactUsForm__successMessage--1LHrm .ContactUsForm__okButton--200TW {
    width: 100%;
  }
}
.ContactUsForm__successMessage--1LHrm .ContactUsForm__okButton--200TW:active,
.ContactUsForm__successMessage--1LHrm .ContactUsForm__okButton--200TW:focus,
.ContactUsForm__successMessage--1LHrm .ContactUsForm__okButton--200TW:hover {
  border-color: transparent;
  background-color: #30b8e9;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ContactUsForm__successMessage--1LHrm .ContactUsForm__okButton--200TW[disabled] {
  background-color: #d7d7d7;
  color: #9b9b9b;
  cursor: not-allowed;
}
.ContactUsForm__musicRequestNote--2iCle {
  margin-bottom: 24px;
}
